import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { StringParam, useQueryParam } from 'use-query-params';

import React from 'react';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { TRACK_FRAGMENT } from '../../gatsby-fragments';
import Header from './BasicHeader';
import Layout from './Layout';
import TrackJoinPage from './TrackJoinPage';

const GET_TRACK = gql`
  query GetTrackToken($token: String!) {
    trackByToken(token: $token) {
      ${TRACK_FRAGMENT}
    }
  }
`;

const TrackTokenPage = () => {
  const [id, _setId] = useQueryParam('id', StringParam);
  const { data, error } = useQuery(GET_TRACK, {
    variables: { token: id },
  });

  const track = data ? data.trackByToken : null;

  if (error) {
    return (
      <Layout>
        <Header />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginTop={10}
        >
          <Alert severity="warning">{error.message}</Alert>
        </Box>
      </Layout>
    );
  }

  if (track) {
    return <TrackJoinPage pageContext={{ track }} />;
  }
  return null;
};

export default TrackTokenPage;
